import styled from 'styled-components';

import { color } from 'common/styledVars';
import ArrowSvg from 'assets/ArrowLow.svg';

export const ArrowIcon = styled(ArrowSvg)`
  transition: all 0.2s linear;
  height: 21px;
  width: 13px;

  g {
    fill: ${color.green.persian};
  }
`;
