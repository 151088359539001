import React from 'react';
import styled, { css } from 'styled-components';

import { ActiveProps } from 'models/Props';
import { color } from 'common/styledVars';
import { ArrowIcon } from 'components/icons';
import { BaseButton, disabledButton } from './index';

type ButtonKind = 'persian' | 'aquaSqueeze' | 'white';

const activeButtonAquaSqueeze = css`
  background-color: ${color.white};
  color: ${color.black};
  svg {
    g {
      fill: ${color.black};
    }
  }
`;

const activeButtonPersian = css`
  background-color: ${color.green.aquaSqueeze};
  color: ${color.green.persian};

  svg {
    g {
      fill: ${color.green.persian};
    }
  }
`;

const activeButtonWhite = css`
  background-color: ${color.green.aquaSqueeze};
  color: ${color.green.persian};
`;

type ButtonKindProps = { [key in ButtonKind]: any };

const getProp = (object: ButtonKindProps) => (key: ButtonKind = 'white') => object[key];

const backgroundColors: ButtonKindProps = {
  white: color.white,
  persian: color.green.persian,
  aquaSqueeze: color.green.aquaSqueeze,
};

const fontColors: ButtonKindProps = {
  white: color.black,
  persian: color.green.aquaSqueeze,
  aquaSqueeze: color.green.persian,
};

const activeStyle: ButtonKindProps = {
  white: activeButtonWhite,
  persian: activeButtonPersian,
  aquaSqueeze: activeButtonAquaSqueeze,
};

const ButtonBody = styled(BaseButton)<ActiveProps & { kind?: ButtonKind }>`
  min-width: 217px;
  height: 62px;
  object-fit: contain;
  border-radius: 17px;
  padding: 0 27px;
  margin-right: auto;

  background-color: ${props => getProp(backgroundColors)(props.kind)};

  color: ${props => getProp(fontColors)(props.kind)};

  ${props =>
    props.kind === 'persian' &&
    css`
      svg {
        g {
          fill: ${color.white};
        }
      }
    `}

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s linear;

  ${ArrowIcon} {
    transform: rotate(-90deg);
    margin-left: 28px;
  }

  &:hover {
    ${props => getProp(activeStyle)(props.kind)};
  }

  ${props => (props.active ? getProp(activeStyle)(props.kind) : undefined)};
  ${props => props.disabled && disabledButton}
`;

interface OwnProps {
  children: React.ReactNode;
  kind?: ButtonKind;
  active?: boolean;
  className?: string;
  type?: 'submit';
  disabled?: boolean;
  onClick?(): void;
}

const ArrowButton = ({ active, children, className, kind, type, disabled, onClick }: OwnProps) => (
  <ButtonBody
    active={active}
    className={className}
    kind={kind}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
    <ArrowIcon />
  </ButtonBody>
);

export default styled(ArrowButton)``;
