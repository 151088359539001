import React from 'react';
import styled from 'styled-components';
import Helmet from "react-helmet";
import { color, mainContainerWidth } from 'common/styledVars';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import ReasonCard from 'components/pages/AboutUs/ReasonCard';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ArrowButton from 'components/Button/ArrowButton';
import {
  TitleSection,
  ReasonsSection,
  ReasonsTitleColumn,
  TeamSection,
  PeopleWrapper,
  BackgroundContainer,
  Column,
  AttributeCardsWrapper,
  BenefitsContent,
} from 'components/pages/AboutUs/styled';
import { BaseTransparentP, BaseP, HeaderMedium, HeaderSmall } from 'components/typography';
import { aboutUsLinks } from 'components/Layout/navigation';
import { aboutUsLinksChinese } from 'components/Layout/navigation';
import { aboutUsLinksKorean } from 'components/Layout/navigation';
import { aboutUsLinksJapanese } from 'components/Layout/navigation';
import { InfinityIcon } from 'components/icons';
import BossCard from 'components/pages/OurStory/BossCard';
import Localisation from 'components/pages/OurStory/Localisation';
import BenefitCard from 'components/pages/OurStory/BenefitCard';
import { WidthWrapper, RelativeWrapper } from 'components/wrappers';
import JoeZhang from 'images/joe-zhang2.png';
import MichaelYin from 'images/michael-yin2.png';
import YannickDeVries from 'images/yannick-de-vries2.png';
import JohnMuir from 'images/john-muir2.png';
import GlobalCoverage from 'images/reason_global2.png';
import DrivenAi from 'images/reason_driven2.png';
import FreeSaaS from 'images/reason_free2.png';
import LowerTax from 'images/reason_lower2.png';
import SaveMoney from 'images/reason_money2.png';
import OnlineBusiness from 'images/reason_online2.png';
import MinimizeInventory from 'images/reason_minimize2.png';
import Growth from 'images/growth2.png';
import Connection from 'images/connection2.png';
import Commitment from 'images/commitment2.png';
import Innovation from 'images/innovation2.png';

import { ReasonsContainer, CardWrapper } from 'components/pages/AboutUs/styled';

const benefits = [
  {
    head: 'Growth',
    text:
      'We will handle your shipments so you can focus on growing your business. Our customers’ growth is the key to our success.',
  },
  {
    head: 'Connection',
    text:
      'We connect people, businesses and countries around the world to a new generation – through logistics.',
  },
  {
    head: 'Innovation',
    text:
      'We are constantly developing better ways of shipping. If we find a way to optimize how we work, we act upon it.',
  },
  {
    head: 'Commitment',
    text:
      'As a service provider we are committed to the success of our customers by providing logistics solutions through reliable service.',
  },
];

const MainTitle = styled.h1`
  margin: 40px 0 60px;
  max-width: 775px;
  font-size: 28px;
  font-weight: 500;

  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 36px;
  }

`;

const TabNav = styled.div`

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }

`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
<<<<<<< HEAD
<<<<<<< HEAD
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
=======
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

interface OwnProps {
  head: string;
  text: string;
  imgSrc: string;
}

const AboutUsPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        <title>About Us | Global Ecommerce Fulfillment Partner | Shipkoo</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "our-story-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "our-story-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "our-story-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "our-story-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Shipkoo is built to be your go-to fulfillment partner. Tailored to create a fast and affordable service for you. Visit us and learn more about our story." />
        <meta property="og:title" content="About Us | Global Ecommerce Fulfillment Partner | Shipkoo" />
        <meta property="og:description" content="Shipkoo is built to be your go-to fulfillment partner. Tailored to create a fast and affordable service for you. Visit us and learn more about our story." />
        <meta name="twitter:title" content="About Us | Global Ecommerce Fulfillment Partner | Shipkoo" />
        <meta name="twitter:description" content="Shipkoo is built to be your go-to fulfillment partner. Tailored to create a fast and affordable service for you. Visit us and learn more about our story." />
      </Helmet>
      <TabNav>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <TabNavigator className="en" tabs={aboutUsLinks} activeTab={aboutUsLinks[0].value} />
          </div>
          <div className="zh">
            <TabNavigator className="zh" tabs={aboutUsLinksChinese} activeTab={aboutUsLinksChinese[0].value} />
          </div>
          <div className="kr">
            <TabNavigator className="kr" tabs={aboutUsLinksKorean} activeTab={aboutUsLinksKorean[0].value} />
          </div>
          <div className="ja">
            <TabNavigator className="ja" tabs={aboutUsLinksJapanese} activeTab={aboutUsLinksJapanese[0].value} />
          </div>
        </div>
      </TabNav>
      <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-about-us" })}</Breadcrumbs>
      <WidthWrapper>
        <TitleSection>
          <BaseP>{intl.formatMessage({ id: "ourstory-hero-subheader" })}</BaseP>
          <MainTitle>
           {intl.formatMessage({ id: "ourstory-hero-header" })}
          </MainTitle>
          <BaseTransparentP>
            {intl.formatMessage({ id: "ourstory-hero-text" })}
          </BaseTransparentP>
        </TitleSection>
      </WidthWrapper>

      <WidthWrapper>
        <BenefitsContent>
          <BaseP>{intl.formatMessage({ id: "ourstory-core-subheader" })}</BaseP>
          <AttributeCardsWrapper>
            <CardWrapper>
              <img src={Growth} alt="Growth" />
              <HeaderSmall>{intl.formatMessage({ id: "ourstory-core-col1-header" })}</HeaderSmall>
              <BaseP>{intl.formatMessage({ id: "ourstory-core-col1-text" })}</BaseP>
            </CardWrapper>
            <CardWrapper>
              <img src={Connection} className="connection" alt="connection" />
              <HeaderSmall>{intl.formatMessage({ id: "ourstory-core-col2-header" })}</HeaderSmall>
              <BaseP>{intl.formatMessage({ id: "ourstory-core-col2-text" })}</BaseP>
            </CardWrapper>
            <CardWrapper>
              <img src={Innovation} alt="Innovation" />
              <HeaderSmall>{intl.formatMessage({ id: "ourstory-core-col3-header" })}</HeaderSmall>
              <BaseP>{intl.formatMessage({ id: "ourstory-core-col3-text" })}</BaseP>
            </CardWrapper>
            <CardWrapper>
              <img src={Commitment} alt="Commitment" />
              <HeaderSmall>{intl.formatMessage({ id: "ourstory-core-col4-header" })}</HeaderSmall>
              <BaseP>{intl.formatMessage({ id: "ourstory-core-col4-text" })}</BaseP>
            </CardWrapper>
          </AttributeCardsWrapper>
        </BenefitsContent>
      </WidthWrapper>
      <RelativeWrapper>
        <WidthWrapper>
          <ReasonsContainer>
            <BaseP><p className="why-shipkoo-title">{intl.formatMessage({ id: "ourstory-why-subheader" })}</p></BaseP>
            <div className="seven-reason">
              <HeaderMedium>
                {intl.formatMessage({ id: "ourstory-why-header" })}
              </HeaderMedium>
            </div>
            <div className="row">
              <div className="column">
                <ReasonCard
                  imgSrc={GlobalCoverage}
                  title={intl.formatMessage({ id: "ourstory-why-col1-header" })}
                  text={intl.formatMessage({ id: "ourstory-why-col1-subheader" })}
                  hover={intl.formatMessage({ id: "ourstory-why-col1-text" })}
                  color="#FFA100"
                  altText=""
                />
              </div>
              <div className="column">
                <ReasonCard
                  imgSrc={FreeSaaS}
                  title={intl.formatMessage({ id: "ourstory-why-col2-header" })}
                  text={intl.formatMessage({ id: "ourstory-why-col2-subheader" })}
                  hover={intl.formatMessage({ id: "ourstory-why-col2-text" })}
                  color="#F36279"
                  altText=""
                />
              </div>
              <div className="column">
                <ReasonCard
                  imgSrc={LowerTax}
                  title={intl.formatMessage({ id: "ourstory-why-col3-header" })}
                  text={intl.formatMessage({ id: "ourstory-why-col3-subheader" })}
                  hover={intl.formatMessage({ id: "ourstory-why-col3-text" })}
                  color="#00B382"
                  altText=""
                />
              </div>
              <div className="column">
                <ReasonCard
                  imgSrc={DrivenAi}
                  title={intl.formatMessage({ id: "ourstory-why-col4-header" })}
                  text={intl.formatMessage({ id: "ourstory-why-col4-subheader" })}
                  hover={intl.formatMessage({ id: "ourstory-why-col4-text" })}
                  color="#00B382"
                  altText=""
                />
              </div>
              <div className="column">
                <ReasonCard
                  imgSrc={SaveMoney}
                  title={intl.formatMessage({ id: "ourstory-why-col5-header" })}
                  text={intl.formatMessage({ id: "ourstory-why-col5-subheader" })}
                  hover={intl.formatMessage({ id: "ourstory-why-col5-text" })}
                  color="#7F1AFF"
                  altText=""
                />
              </div>
              <div className="column part-six">
                <ReasonCard
                  imgSrc={OnlineBusiness}
                  title={intl.formatMessage({ id: "ourstory-why-col6-header" })}
                  text={intl.formatMessage({ id: "ourstory-why-col6-subheader" })}
                  hover={intl.formatMessage({ id: "ourstory-why-col6-text" })}
                  color="#F36279"
                  altText=""
                />
              </div>
              <div className="column-last-row">
                <ReasonCard
                    imgSrc={MinimizeInventory}
                    title={intl.formatMessage({ id: "ourstory-why-col7-header" })}
                    text={intl.formatMessage({ id: "ourstory-why-col7-subheader" })}
                    hover={intl.formatMessage({ id: "ourstory-why-col7-text" })}
                    color="#F36279"
                    altText=""
                  />
              </div>
            </div>
            <div className="get-in-touch">
              <Link to="/contact">
                <ArrowButton kind="aquaSqueeze">{intl.formatMessage({ id: "get-in-touch" })}</ArrowButton>
              </Link>
            </div>
          </ReasonsContainer>
        </WidthWrapper>
      </RelativeWrapper>
    </Layout>
  );
};

export default AboutUsPage;
