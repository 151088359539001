import styled, { css } from 'styled-components';
import { cover } from 'polished';

export const BaseButton = styled.button`
  display: inline-block;
  position: relative;
  overflow: hidden;
  user-select: none;
  border: none;
  text-align: center;
  background: transparent;
  padding: 0;

  :focus {
    outline: none;
  }
  ::-moz-focus-inner {
    border: 0;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
`;

export const disabledButton = css`
  cursor: not-allowed;
  &:after {
    content: ' ';
    ${cover()};
    background-color: #000;
    opacity: 0.3;
  }
`;

